import { ApolloError } from '@apollo/client/errors'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { loadStripe } from '@stripe/stripe-js'
import { encode } from 'js-base64'
import _ from 'lodash'
import { DateTime } from 'luxon'
import slugifyLib from 'slugify'
import { ItList } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

import { toast } from 'src/components/widgets/Toaster'
import { colors } from 'src/theme/colors'
import { BrandColor, ThemeColor } from 'src/types'

import { BIO_LINK_MAX_LENGTH, URL_REGEX } from './constants'
import { PRICING_PAGE_URL } from './constants'
import { safari } from './detectBrowser'

export function capitalize(str?: string | null): string {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : ''
}

export function fillArray<T>(length: number, value: T | number = 0) {
  return new Array(length).fill(value)
}

export function generateUid(): string {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
}

export function generateNumber(max = 100000000): number {
  return Math.floor(Math.random() * max)
}

export const isValidUrl = (url: string): boolean => {
  try {
    const urlObj = new URL(url)
    const hasTopLevelDomain = !!urlObj.hostname.match(/\.[a-z]{2,}\/?/)
    return hasProtocol(url) && hasTopLevelDomain
  } catch (err: unknown) {
    return false
  }
}

export const getDomain = (url: string): string => {
  try {
    const urlObj = new URL(url.match(/^https?:\/\//) ? url : `http://${url}`)
    const hostParts = urlObj.hostname.split('.')
    return `${hostParts[hostParts.length - 2]}.${
      hostParts[hostParts.length - 1]
    }`.toLocaleLowerCase()
  } catch (err) {
    console.error(err)
  }
  return url
}

export const toAgoString = (date: DateTime): string => {
  if (date.plus({ day: 2 }) > DateTime.now())
    return capitalize(date.toRelativeCalendar())
  else return date.toRelative()
}

export const getBrandColor = (
  creator?: { brandColorBrightness?: string; brandHue?: string },
  separator = '.',
  defaultColor = 'onyx'
): BrandColor => {
  return (
    creator?.brandColorBrightness && creator?.brandHue
      ? `${creator.brandHue}${separator}${creator.brandColorBrightness}`
      : defaultColor
  ) as BrandColor
}

export const getColorHexValue = (color: ThemeColor) => _.get(colors, color)

export const getFullName = ({
  firstName,
  lastName,
  fallback,
}: {
  firstName?: string
  lastName?: string
  fallback?: string
}) =>
  firstName || lastName
    ? [firstName, lastName].filter((n) => !!n).join(' ')
    : fallback || ''

// price = 99 // output: $0.99
// price = 9900 // output: $99
// price = 9910 // output: $99.10
export const asPrice = (price: number) => {
  return (price / 100)
    .toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
    .replace(/\.00$/, '')
}

export const isInIFrame = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return false
  }
}

export function getProfileUrl(
  username = ''
): `http://${string}` | `https://${string}` {
  return getAbsoluteUrl(routes.profile({ username }))
}

export function getAbsoluteUrl(
  relativeUrl = ''
): `http://${string}` | `https://${string}` {
  return (
    relativeUrl.match(/^https?:\/\//)
      ? relativeUrl
      : `${process.env.HOME_URL}${relativeUrl}`
  ) as `http://${string}` | `https://${string}`
}

export function currentUrl(
  search?: Record<string, string>,
  options?: { relative?: boolean }
): string {
  const url = new URL(window.location.href)
  Object.entries(search || {}).forEach(([key, value]) => {
    url.searchParams.delete(key)
    url.searchParams.append(key, value)
  })
  return options?.relative
    ? url.pathname + url.href.split(url.pathname)[1]
    : url.href
}

export function openUrl(url: string) {
  //This check is needed due to safari's popup blocker.
  if (safari) {
    window.location.href = url
  } else {
    window.open(url, '_blank')
  }
}

export function removeDoubleSlashes(url: string) {
  return url.replace(/([^:]\/)\/+/g, '$1')
}

export function slugify(str?: string): string {
  return slugifyLib(str || '', {
    replacement: '-', // replace spaces with replacement character
    lower: true, // convert to lower case
    strict: true, // strip special characters except replacement
    trim: true, // trim leading and trailing replacement chars
  })
}

export const toCamelKeys = <T>(obj?: Record<string, T>): Record<string, T> =>
  obj
    ? Object.keys(obj).reduce(
        (acc, key) => ({ ...acc, [_.camelCase(key)]: obj[key] }),
        {}
      )
    : {}

export const hasProtocol = (url: string) =>
  url.search(/^[a-z]{2,}?\:\/\//) != -1

export const updateHttpToHttps = (url: string): string =>
  `https:${url.replace(/^http(s?):/, '')}`

export const enforceHttps = (url: string) => {
  const httpsUrl = `https://${url.replace(
    /^([a-zA-Z]*:\/?\/?)|^([a-zA-Z]*\/\/)/,
    ''
  )}`
  if (!isValidUrl(httpsUrl)) throw new Error('Not a valid url')
  return httpsUrl
}

export const getLinkDisplayName = (urlStr: string, name?: string): string => {
  if (name) return capitalize(name)
  try {
    const url = new URL(enforceHttps(urlStr))
    const splitHostName = url.hostname.split('.')
    return capitalize(
      splitHostName.length > 2 ? splitHostName[1] : splitHostName[0]
    )
  } catch {
    return urlStr.slice(0, BIO_LINK_MAX_LENGTH - 3) + '...'
  }
}

export const validationErrorOrDefault = (
  err: unknown,
  defaultMessage: string
) => {
  if ((err as ApolloError)?.name === 'ApolloError') {
    const apolloError = err as ApolloError
    if (
      apolloError.graphQLErrors?.length &&
      apolloError.graphQLErrors[0]?.extensions.code ===
        'GRAPHQL_VALIDATION_FAILED'
    ) {
      return apolloError.graphQLErrors[0].message
    }
  }
  return defaultMessage
}

export const encodeURIValues = (
  params?: Record<string, string | number | boolean>
) =>
  params
    ? Object.entries(params).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: encodeURIComponent(value) }),
        {}
      )
    : null

export const openLinkInNewTab = (url?: string) => {
  url && window.open(enforceHttps(url), '_blank')
}

export const matchUrl = (site: string, url?: string) => {
  if (!url || !url.match(URL_REGEX)) {
    return false
  }
  let formattedUrl = url
  if (
    !formattedUrl.startsWith('http://') &&
    !formattedUrl.startsWith('https://')
  ) {
    formattedUrl = `http://${formattedUrl}`
  }
  const urlDomain = new URL(formattedUrl).hostname.toLowerCase()
  return urlDomain === site || urlDomain.endsWith(`.${site}`)
}

export const arrayMove = <T>(
  arr: T[],
  source: number,
  destination: number
): T[] => {
  // Note that splice is always mutating the array
  arr.splice(destination, 0, arr.splice(source, 1)[0])
  return arr
}

export function possessiveForm(noun: string): string {
  return noun ? `${noun}${noun.endsWith('s') ? "'" : "'s"}` : null
}

export function chakraColorToCSSVariable(color: string) {
  return `var(--chakra-colors-${color.replace('.', '-')})`
}

export const scrollCSS = {
  css: {
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: colors.stone,
      borderRadius: '3px',
    },
  },
}

export const thinScrollCSS = {
  css: {
    '&::-webkit-scrollbar': {
      width: '2px',
    },
    '&::-webkit-scrollbar-track': {
      width: '2px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: colors.stone,
    },
  },
}

export function pathJoin(...paths: string[]): string {
  return paths.map((path) => path.replace(/^\/|\/$/g, '')).join('/')
}

export const isLocalhost = process.env.HOME_URL?.includes('localhost')

export const copyToClipboard = async (text: string, toastLabel?: string) => {
  await navigator.clipboard.writeText(text)
  if (toastLabel)
    toast(
      'success',
      toastLabel,
      { iconRight: faCheck },
      { id: 'copyLink', duration: 1500 }
    )
}

export const isE2ETestSession = () =>
  navigator?.userAgent === 'Cypress Headless Chrome'

export function formatTime(time: number) {
  return `${Math.floor(+time / 60)}:${String(Math.round(+time) % 60).padStart(
    2,
    '0'
  )}`
}

export const plural = (
  count: number,
  pluralSuffix = 's',
  singularSuffix = ''
) => {
  return count === 1 ? singularSuffix : pluralSuffix
}

export const checksum = (s: string) => {
  let chk = 0x12345678
  const len = s.length
  for (let i = 0; i < len; i++) {
    chk += s.charCodeAt(i) * (i + 1)
  }

  return (chk & 0xffffffff).toString(16)
}

export const pause = (amountMs: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve()
    }, amountMs)
  })
}

export const buildQueryParamsWithItList = (
  itList: ItList,
  otherParams?: { [x: string]: string }
) => {
  const { purchaseDate, price, name, coverUrl, id, creator } = itList
  return {
    itList: encode(
      JSON.stringify({
        price,
        name,
        coverUrl,
        id,
        purchaseDate,
        creator: _.pick(creator, [
          'id',
          'brandColorBrightness',
          'brandHue',
          'email',
          'firstName',
          'lastName',
          'username',
        ]),
      })
    ),
    ...encodeURIValues(otherParams),
  }
}

export const isProduction = () => process.env.HOME_URL === 'https://itlist.co'

export const gotoPricingPage = () => {
  if (isProduction()) {
    location.href = PRICING_PAGE_URL
  } else {
    navigate(routes.pricingTest())
  }
}

export const gotoHomePage = () => {
  if (isProduction()) location.href = 'https://home.itlist.co'
  return routes.signin()
}

export const stripePromise = loadStripe(process.env.STRIPE_PK)

export const removeQueryParam = (parameter: string) => {
  const urlObj = new URL(location.href)
  urlObj.searchParams.delete(parameter)
  history.replaceState(null, '', urlObj.toString())
}
