export const fontWeight = {
  regular: '400',
  medium: '500',
  semibold: '600',
  bold: '700',
}

export const typography = {
  html: {
    fontSize: '10px',
  },
  body: {
    fontSize: '16px',
    color: 'onyx',
    fontFamily: 'Plus Jakarta Sans, helvetica, arial, sans-serif',
    background: 'white',
    letterSpacing: '-0.1px',
  },
  h1: {
    fontSize: { base: '4rem', md: '5.8rem' },
    fontWeight: { base: fontWeight.semibold, md: fontWeight.medium },
    lineHeight: { base: '1.25', md: '1.25' },
  },
  h2: {
    fontSize: { base: '3.4rem', md: '3.8rem' },
    fontWeight: fontWeight.medium,
    lineHeight: { base: '1.4', md: '1.25' },
  },
  'h2.thick': {
    fontSize: { base: '3.4rem', md: '3.8rem' },
    fontWeight: { base: fontWeight.bold, md: fontWeight.semibold },
    lineHeight: { base: '1.4', md: '1.25' },
  },
  h3: {
    fontSize: { base: '2.6rem', md: '3.2rem' },
    fontWeight: fontWeight.medium,
    lineHeight: '1.25',
  },
  'h3.thick': {
    fontSize: { base: '2.6rem', md: '3.2rem' },
    fontWeight: fontWeight.bold,
    lineHeight: '1.25',
  },
  h4: {
    fontSize: { base: '2.2rem', md: '2.4rem' },
    fontWeight: fontWeight.medium,
    lineHeight: { base: '1.5', md: '1.4' },
  },
  'h4.thick': {
    fontSize: { base: '2.2rem', md: '2.4rem' },
    fontWeight: fontWeight.bold,
    lineHeight: { base: '1.5', md: '1.4' },
  },
  '.h4-thick': {
    fontSize: { base: '2.2rem', md: '2.4rem' },
    fontWeight: fontWeight.bold,
    lineHeight: { base: '1.5', md: '1.4' },
  },
  h5: {
    fontSize: { base: '1.6rem', md: '1.8rem' },
    fontWeight: fontWeight.medium,
    lineHeight: { base: '1.25', md: '1.25' },
  },
  'h5.thick': {
    fontSize: { base: '1.6rem', md: '1.8rem' },
    fontWeight: fontWeight.bold,
    lineHeight: { base: '1.25', md: '1.25' },
  },
  '.body-regular': {
    fontSize: { base: '1.6rem', md: '1.7rem' },
    fontWeight: fontWeight.regular,
    lineHeight: '1.6',
  },
  '.body-regular-emphasis': {
    fontSize: { base: '1.6rem', md: '1.7rem' },
    fontWeight: fontWeight.semibold,
    lineHeight: '1.6',
  },
  '.body-small': {
    fontSize: { base: '1.4rem', md: '1.6rem' },
    fontWeight: fontWeight.regular,
    lineHeight: '1.6',
  },
  '.body-small-emphasis': {
    fontSize: { base: '1.4rem', md: '1.6rem' },
    fontWeight: fontWeight.semibold,
    lineHeight: '1.6',
  },
  '.body-extra-small': {
    fontSize: { base: '1.2rem', md: '1.4rem' },
    fontWeight: fontWeight.regular,
    lineHeight: '1.5',
  },
  '.body-extra-small-emphasis': {
    fontSize: { base: '1.2rem', md: '1.4rem' },
    fontWeight: fontWeight.medium,
    lineHeight: '1.5',
  },
  '.body-extra-small-heavy': {
    fontSize: { base: '1.2rem', md: '1.4rem' },
    fontWeight: fontWeight.bold,
    lineHeight: '1.5',
  },
  '.chip': {
    fontSize: { base: '1.4rem', md: '1.4rem' },
    fontWeight: fontWeight.regular,
    lineHeight: '1.5',
  },
  '.input-label': {
    fontSize: { base: '1rem', md: '1rem' },
    fontWeight: fontWeight.semibold,
    lineHeight: '1.5',
  },
  '.button-text-link-small': {
    fontSize: { base: '1.4rem', md: '1.6rem' },
    fontWeight: fontWeight.semibold,
    lineHeight: '1.5',
    textDecoration: 'underline',
  },
}

export const editorPreviewTypography = {
  '.preview h1': {
    fontSize: typography.h1.fontSize.base,
    fontWeight: typography.h1.fontWeight.base,
    lineHeight: typography.h1.lineHeight.base,
  },
  '.preview h2': {
    fontSize: typography.h2.fontSize.base,
    fontWeight: typography.h2.fontWeight,
    lineHeight: typography.h2.lineHeight.base,
  },
  '.preview h2.thick': {
    fontSize: typography['h2.thick'].fontSize.base,
    fontWeight: typography['h2.thick'].fontWeight.base,
    lineHeight: typography['h2.thick'].lineHeight.base,
  },
  '.preview h3': {
    fontSize: typography.h3.fontSize.base,
    fontWeight: typography.h3.fontWeight,
    lineHeight: typography.h3.lineHeight,
  },
  '.preview h3.thick': {
    fontSize: typography['h3.thick'].fontSize.base,
    fontWeight: typography['h3.thick'].fontWeight,
    lineHeight: typography['h3.thick'].lineHeight,
  },
  '.preview h4': {
    fontSize: typography.h4.fontSize.base,
    fontWeight: typography.h4.fontWeight,
    lineHeight: typography.h4.lineHeight.base,
  },
  '.preview h4.thick': {
    fontSize: typography['h4.thick'].fontSize.base,
    fontWeight: typography['h4.thick'].fontWeight,
    lineHeight: typography['h4.thick'].lineHeight.base,
  },
  '.preview h5': {
    fontSize: typography.h5.fontSize.base,
    fontWeight: typography.h5.fontWeight,
    lineHeight: typography.h5.lineHeight.base,
  },
  '.preview h5.thick': {
    fontSize: typography['h5.thick'].fontSize.base,
    fontWeight: typography['h5.thick'].fontWeight,
    lineHeight: typography['h5.thick'].lineHeight.base,
  },
  '.preview .body-regular': {
    fontSize: typography['.body-regular'].fontSize.base,
    fontWeight: typography['.body-regular'].fontWeight,
    lineHeight: typography['.body-regular'].lineHeight,
  },
  '.preview .body-regular-emphasis': {
    fontSize: typography['.body-regular-emphasis'].fontSize.base,
    fontWeight: typography['.body-regular-emphasis'].fontWeight,
    lineHeight: typography['.body-regular-emphasis'].lineHeight,
  },
  '.preview .body-small': {
    fontSize: typography['.body-small'].fontSize.base,
    fontWeight: typography['.body-small'].fontWeight,
    lineHeight: typography['.body-small'].lineHeight,
  },
  '.preview .body-small-emphasis': {
    fontSize: typography['.body-small-emphasis'].fontSize.base,
    fontWeight: typography['.body-small-emphasis'].fontWeight,
    lineHeight: typography['.body-small-emphasis'].lineHeight,
  },
  '.preview .body-extra-small': {
    fontSize: typography['.body-extra-small'].fontSize.base,
    fontWeight: typography['.body-extra-small'].fontWeight,
    lineHeight: typography['.body-extra-small'].lineHeight,
  },
  '.preview .body-extra-small-emphasis': {
    fontSize: typography['.body-extra-small-emphasis'].fontSize.base,
    fontWeight: typography['.body-extra-small-emphasis'].fontWeight,
    lineHeight: typography['.body-extra-small-emphasis'].lineHeight,
  },
  '.preview .input-label': {
    fontSize: typography['.input-label'].fontSize.base,
    fontWeight: typography['.input-label'].fontWeight,
    lineHeight: typography['.input-label'].lineHeight,
  },
}
