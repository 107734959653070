import { Box, SystemProps } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import { Link } from '@redwoodjs/router'

import ItListLogoColor from 'src/assets/image/ItlistLogoColorSVG'
import ItListLogo from 'src/assets/image/ItlistLogoWordmarkSVG'
import { brandLogoState } from 'src/atoms/ui.atom'
import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'

import ItListImage from '../ItListImage/ItListImage'

type LogoProps = {
  to?: string
} & SystemProps

const Logo = ({ to, ...props }: LogoProps) => {
  const { isAuthenticated, brandLogo: brandLogoFromUser } = useCurrentUser()
  const brandLogoFromStore = useRecoilValue(brandLogoState)
  const brandLogo = brandLogoFromUser || brandLogoFromStore

  const Logo = () => {
    if (brandLogo) {
      if (brandLogo === 'loading') {
        return null
      }
      return (
        <ItListImage
          alt="brand logo"
          h="48px"
          htmlHeight={96}
          src={brandLogo}
        />
      )
    }
    if (!isAuthenticated) {
      return <ItListLogoColor width="75px" height="41px" />
    }
    return <ItListLogo />
  }

  return to ? (
    <Box {...props} data-testid="logo">
      <Link to={to}>
        <Logo />
      </Link>
    </Box>
  ) : (
    <Box {...props} data-testid="logo">
      <Logo />
    </Box>
  )
}

export default Logo
